* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
}

*:active, *:focus {
  outline: none;
}

html, body {
  font-size: 1.3rem; /* Fall back */
  line-height: 1.5em;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  padding: 0;
  margin: 0;
}
body {
  position: initial !important;
  min-height: unset !important;
  top: unset !important;
}

.button-link {
  padding: 0;
  background: transparent;
  margin: 0;
  display: inline-block;
  text-transform: unset;
  border-radius: 0;
  font-size: inherit;
}

.button-link,
a, a:visited, a:active {
  color: #E53935;
  text-decoration: none;
}

.button-link:hover,
a:hover {
  color: #a12828;
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
  margin: 1em;
}

.bg-gray {
  background-color: #efefef;
}
.qr-code {
  width: auto;
  height: 125px;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.description {
  line-height: 1.5;
  color: #444444;
}

.no-margin {
  margin: 0;
}
.medium-text {
  font-size: .95rem;
  line-height: 1.5;
}
.small-text {
  font-size: .75rem;
  line-height: 1.5;
}
.single-line-height {
  line-height: 1.25 !important;
  display: inline-block;
}
.intl {
  font-size: 0.8rem;
}
.logo-block {
  display: flex;
  align-items: center;
}
.logo-block .logo-title {
  font-weight: 900;
  color: #444;
  font-size: 18px;
  margin-left: 10px;
}
.logo {
  width: 5rem;
  margin: .5em;
}
.inline {
  display: inline;
}

/* Disclaimer and Backdrop */
.body {
  position: initial !important;
}
.backdrop-visible {
  overflow: hidden;
}
.hide {
  display: none;
}
.show {
  display: block;
}
#disclaimer {
  position: fixed;
  top: 25%;
  width: 60%;
  left: 20%;
  background: white;
  padding: 1rem;
  z-index: 3;
}
#checkboard {
  position: fixed;
  top: 25%;
  width: 60%;
  left: 20%;
  background: white;
  padding: 1rem;
  z-index: 3;
}
#backdrop {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
  z-index: 2;
  overflow: hidden;
}

/* Layout */
.row, .block {
  display: block;
}

.row {
  width: 60rem;
  max-width: 100%;
  margin: 0 auto;
}
.col {
  display: inline-block;
  vertical-align: middle;
}
.row.vertical-top .col {
  vertical-align: top;
}

.c5 {
  width: 5%;
}

.c10 {
  width: 10%;
}

.c15 {
  width: 15%;
}

.c20 {
  width: 20%;
}

.c25 {
  width: 25%;
}

.c30 {
  width: 30%;
}

.c35 {
  width: 35%;
}

.c40 {
  width: 40%;
}

.c45 {
  width: 45%;
}

.c50 {
  width: 50%;
}

.c55 {
  width: 55%;
}

.c60 {
  width: 60%;
}

.c65 {
  width: 65%;
}

.c70 {
  width: 70%;
}

.c75 {
  width: 75%;
}

.c80 {
  width: 80%;
}

.c85 {
  width: 85%;
}

.c90 {
  width: 90%;
}

.c95 {
  width: 95%;
}

.c100 {
  width: 100%;
}

.padding {
  padding: 1rem;
}

.side-padding {
  padding: 0 1rem;
}

.top-bottom-padding {
  padding: 1rem 0;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

footer {
  background: #f8f8f8;
  padding: 0.5rem;
  font-size: 0.7rem;
  line-height: 1.25;
}

/* Translations */
.goog-te-gadget-simple {
  border: none !important;
}

.goog-te-gadget-icon {
  display: none;
}

/* Animations */

@keyframes blink {
  0% {
    color: white;
  }
  50% {
    color: #444;
  }
  100% {
    color: white;
  }
}


/* Board */

#board {
  background: #222222;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.board-row {
  display: block;
}

.letter, .ball {
  display: inline-block;
  text-align: center;
  color: #444444;
  width: 6.25%;
  line-height: 1.75em;
}

.letter {
  color: #E53935;
  background: white;
  padding: 0.25em;
  font-weight: 700;
}

.called.ball:not(.active) {
  color: white;
}

.active.ball {
  animation: blink 1s infinite;
}

/* Buttons */

#buttons {
  padding: 0.5em 1em;
  background: #e63935;
}

button {
  margin: 0 0.25em;
  border: 0;
  background: #bd2a26;
  border-radius: 0.2em;
  font-size: 1rem;
  padding: 0.5em 1em;
  color: white;
  text-transform: uppercase;
}

#buttons button {
  margin: 0.25em;
}

button:disabled {
  background: #aaaaaa;
}

button:active {
  outline: none;
}

button:hover {
  cursor: pointer;
}

/* Speed Selector */

#speed {
  margin: 0.75em;
}

#speed span {
  text-transform: uppercase;
  font-size: 0.8em;
  color: white;
  font-weight: normal;
}

input[type='range'] {
  appearance: none;
  width: 10rem;
  height: .5rem;
  outline: none;
  border-radius: .25rem;
  margin: 0 1rem;
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

input[type='range']::-webkit-slider-thumb {
  appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #F44336;
  border: .25rem solid white;
  border-radius: 1rem;
  cursor: pointer;
}


/* Select */

.Select {
  margin: 0.5em auto;
  font-size: 0.8rem;
  width: 100%;
}

#buttons .Select {
  width: 100%;
  text-align: left;
  font-size: 0.8rem;
}
.Select-control,
.Select-control * {
  height: auto !important;
}

.Select-input > input {
  padding: 0;
}

.Select-placeholder, 
.Select--single > .Select-control .Select-value {
  bottom: 0;
  color: #aaa;
  left: 0;
  line-height: 2.5 !important;
  font-size: .8rem;
  height: auto;
}

/* Ball Graphic */

#ball {
  width: 100%;
  display: flex;
  border-radius: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  max-width: 180px !important;
  margin: 0 auto;
  box-shadow: inset 0.5rem 0.5rem 1.5rem 0.25rem rgba(255, 255, 255, 0.3),
  inset -0.25rem -0.25rem 1.5rem 0.5rem rgba(0, 0, 0, 0.4)
}
@-moz-document url-prefix() {
  #ball {
    height: 180px;
  }
}
  #ball:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.content {
  text-align: center;
  border-radius: 100%;
  background: white;
  position: relative;
  min-width: 1rem;
  padding: 28%;
  box-shadow: inset -0.5rem -0.5rem 2rem 0 rgba(0, 0, 0, 0.16), 0 0 0 0.25rem white;
  background: radial-gradient(circle at 20% 20%, white, #efefef);
}

.content > span {
  justify-content: center;
  align-items: center;
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1;
}
.content .ball-letter {
  font-size: 1em;
}
.content .ball-number {
  font-size: 1.5em;
}

.content img {
  padding: 0.25rem;
  margin: 0;
}

#currentBall {
  box-shadow: inset 0.5rem 0.5rem 1.5rem 0.25rem rgba(255, 255, 255, 0.3),
  inset -0.25rem -0.25rem 1.5rem 0.5rem rgba(0, 0, 0, 0.4);
}

#ballcount {
  position: absolute;
  bottom: -2rem;
  color: #e63935;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}

#ballcount:before {
  content: 'Call #';
  color: #ffffff;
  margin-right: 0.25em;
}

.blue {
  background: radial-gradient(circle at 20% 20%, #0000FF, #0000ad);
}

.blue .content {
  border: 0.15rem solid #0000FF;
}

.red {
  background: radial-gradient(circle at 20% 20%, #FF0000, #910000);
}

.red .content {
  border: 0.15rem solid #FF0000;
}

.white {
  background: radial-gradient(circle at 20% 20%, #ffffff, #b6b6b6);
}

.white .content {
  border: 0.15rem solid #FF0000;
}

.green {
  background: radial-gradient(circle at 20% 20%, #008000, #004a00);
}

.green .content {
  border: 0.15rem solid #008000;
}

.yellow {
  background: radial-gradient(circle at 20% 20%, #ffe52a, #f7bd00)
}

.yellow .content {
  border: 0.15rem solid #f7bd00;
}

/* Pattern */

#bingopattern {
  white-space: nowrap;
  text-align: center;
  display: block;
  width: 100%;
  margin: 1rem auto;
}
#bingopattern .Select {
  width: 10rem;
  max-width: 100%;
}

.pattern-col {
  display: inline-block;
  vertical-align: top;
}

.pattern-letter, .pattern-slot {
  width: 2.5em;
  height: 2.5em;
  padding: 0.35em;
  font-size: 0.75rem;
  line-height: 1.75em;
  background: #f3f3f3;
  text-align: center;
  position: relative;
  cursor: pointer;
  border: 1px solid #ffffff;
}

.pattern-letter {
  background: #E53935;
  color: white;
  border: 1px solid #E53935
}

.pattern-slot.selected:after {
  background: #E53935;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: '';
  border-radius: 100%;
  margin: 20%;
}


/* Media Queries */
@media (max-width: 500px) {
  html, body {
    font-size: .9rem;
  }

  section .col, section .flex,
  footer .col, footer .flex {
    width: 100% !important;
    display: block;
  }

  #buttons {
    margin: 0 auto;
    text-align: center;
  }

  .board {
    text-align: center;
    font-size: 1.25rem;
  }

  .board-row {
    display: inline-block;
  }

  .letter {
    background: #E53935;
    color: white;
  }

  .letter, .ball {
    display: block;
    width: 2em;
    height: auto;
    line-height: 1.5em;
  }

  footer * {
    text-align: center;
  }
  #ballcount {
    position: absolute;
    right: initial;
    bottom: -2.25rem;
    color: #e63935;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
  }
  #ball {
    margin-bottom: 2rem;
  }
}


@media screen and (min-width: 500px) and (max-width: 1200px) {
  #board .c85 {
    width: 75%;
  }

  #board .c15 {
    width: 25%;
  }
}

@media screen and (min-width: 1200px){
  button {
    font-size: 0.8rem !important;
  }
  #buttons * {
    font-size: 0.8rem !important;
  }
  .col.c85 {
    width: 80%;
  }
  .col.c15 {
    width: 20%;
  }
  .letter, .ball {
    line-height: 1.5;
    font-size: 1.5rem;
  }
}