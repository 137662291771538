.bingo-container {
  width: 90%;
  margin: 0 auto;
}

.bingo-heading {
  text-align: center;
}

.grid-container {
  display: flex;
}

.numbers-grid {
  width: 66.66%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 5px;
}

.number-box {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  text-align: center;
}

.selected{
  background-color: #267003;
}

.input-section {
  width: 33.33%;
  padding-left: 20px;
}

.input-group {
  margin-bottom: 20px;
}

.board-group {
  margin-bottom: 20px;
}

.chips-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.chip {
  background-color: #007bff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 20px;
}

.remove-btn {
  margin-left: 10px;
}

.add-btn {
  margin-top: 10px;
}

.submit-btn {
  margin-top: 20px;
}

.login-message {
  text-align: center;
  margin-top: 50px;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 20px;
}
